
import { defineComponent, onBeforeMount, reactive, toRefs, nextTick, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { userApi } from '@/api/modules/user'

export default defineComponent({
  name: 'adminLog',
  setup (props, ctx) {
    onBeforeMount(() => {
      data.init()
    })

    // 获取refs
    const tableListRef = ref()

    const data: any = reactive({
      // 表格
      list: [],
      loading: true,
      query: {
        page: 1,
        paginate: 20,
        username: '',
        log_info: ''
      },
      total: 0, // 总条数

      // 初始化
      init () {
        data.query.page = 1
        data.query.username = ''
        data.query.log_info = ''
        data.getList()
      },

      // 数据获取
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }
        const { res } = await userApi.getUserLogList(data.query)
        console.log('获取列表', res.data)

        data.loading = false
        data.list = res.data
        data.total = res.total
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      }
    })

    return {
      ...toRefs(data),
      tableListRef
    }
  }
})
